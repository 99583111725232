.rotatingSatellite {
  animation-name: rotatingSatellite;
  animation-duration: 50s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes rotatingSatellite {
  0% {
    transform: rotate(360deg);
  }
}

.book-appt-text {
  background-color: white;
}

.book-appt-text:hover {
  background-color: rgb(250, 250, 250);
}
